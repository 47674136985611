import React from 'react';
import { scrollToSignUp } from '../../services/methods';
import global from '../../styles/global.module.scss'
import styles from './styles.module.scss';

// eslint-disable-next-line
export default () => {
    return(
        <div className={styles.payments_wrap} id="payouts">
            <div className={global.container}>
                <div className={styles.payments}>
                    <div className={styles.payments_row}>
                        <div className={styles.payments_item}>
                            <div className={styles.payments_item_title}>Get up to <span>$500</span></div>
                            <div className={styles.payments_item_text}>per first order</div>
                        </div>
                        <div className={styles.payments_item}>
                            <div className={styles.payments_item_title}>Receive up to <span>$15</span></div>
                            <div className={styles.payments_item_text}>per registration</div>
                        </div>
                    </div>
                    <div className={styles.payments_join_btn} href="#sign_up" onClick={scrollToSignUp}>Join us now</div>
                </div>
            </div>
        </div>
    )
}