import React from 'react';
import { scrollToSignUp } from '../../services/methods';
import global from '../../styles/global.module.scss'
import styles from './styles.module.scss';

// eslint-disable-next-line
export default () => {

    return(
        <div  className={styles.main_wrap}>
            <div className={global.head_container}>
                <div className={styles.main}>
                    <div className={styles.main_title}>Become a Member of our Dating Affiliate Program</div>
                    <div className={`${styles.main_text} ${styles.pc}`}>The Sofia Date Affiliate Program is your unique chance to make impressive revenue!
                        <br/>Choose from a varied range of Sofia Date Affiliate Network that provides stable income when you create registrations, generate conversions and sales on our sites.</div>
                    <div className={`${styles.main_text} ${styles.mob}`}>
                        The Sofia Date Affiliate Program is your unique chance to make impressive revenue!
                    </div>
                    <div className={styles.main_become_btn} href="#sign_up" onClick={scrollToSignUp}>Become a member</div>
                </div>
            </div>
        </div>
    )
}