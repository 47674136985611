import React, {useEffect, useRef} from 'react';
import styles from './styles.module.scss';

import * as IMAGES from '../../Images';
import { scrollToSignUp } from '../../services/methods';

// eslint-disable-next-line
export default () => {
    const headerRef = useRef(null);

    useEffect(()=>{
        function scrollHead () {
            if (window.scrollY >= 10) {
                headerRef.current.classList.add("active");
            } else {
                headerRef.current.classList.remove("active");
            }
        }

        window.addEventListener("scroll", scrollHead);

        return () =>  window.removeEventListener("scroll", scrollHead);
    }, []);

    return(
        <div className={`${styles.header} header`} ref={headerRef}>
            <div className={styles.header_logo}>
                <img src={IMAGES.logo} alt=""/>
            </div>
            <div className={styles.header_nav}>
                <a href="#about_us" className={styles.header_nav_item} onClick={scrollToSignUp}>About us</a>
                <a href="#payouts" className={styles.header_nav_item} onClick={scrollToSignUp}>Payouts</a>
                <a href="#news" className={styles.header_nav_item} onClick={scrollToSignUp}>News</a>
                <a href="#testim" className={styles.header_nav_item} onClick={scrollToSignUp}>Testimonials</a>
                <a href="#sign_up" className={`${styles.header_nav_item} ${styles.sign_up}`} onClick={scrollToSignUp}>Sing up</a>
            </div>
        </div>
    )
}