import React from 'react';
import global from '../../styles/global.module.scss'
import styles from './styles.module.scss';
import * as IMAGES from '../../Images';

// eslint-disable-next-line
export default () => {
    return(
        <div className={styles.about_us_wrap} id="about_us">
            <div className={global.container}>
                <div className={styles.about_us}>
                    <div className={styles.about_us_left}>
                        <div className={styles.about_us_left_title}>Why team up with us?

                        </div>
                        <div className={styles.about_us_left_text}>Sofia Date is a relatively young product on the dating market that is widely gaining its popularity among users all over the world. With a vast pool of gorgeous Slavic women and their enormous success with foreign members, Sofia Date is the number one choice for affiliates fond of lucid financial rewards.</div>
                    </div>
                    <div className={styles.about_us_right}>
                        <div className={styles.about_us_right_col}>
                            <div className={styles.about_us_right_item}>
                                <img src={IMAGES.ab_1} alt="" className={styles.about_us_right_item_ico}/>
                                <span>Flexibility of payments <br/>& timely payouts</span>
                            </div>
                            <div className={styles.about_us_right_item}>
                                <img src={IMAGES.ab_2} alt="" className={styles.about_us_right_item_ico}/>
                                <span>Сommitted <br/>support</span>
                            </div>
                        </div>
                        <div className={styles.about_us_right_col}>
                            <div className={styles.about_us_right_item}>
                                <img src={IMAGES.ab_3} alt="" className={styles.about_us_right_item_ico}/>
                                <span>Weekly <br/>Payouts</span>
                            </div>
                            <div className={styles.about_us_right_item}>
                                <img src={IMAGES.ab_4} alt="" className={styles.about_us_right_item_ico}/>
                                <span>Sales with high <br/>commission</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}