import React, {useRef, useState} from 'react';
import global from '../../styles/global.module.scss'
import styles from './styles.module.scss';
// import * as IMAGES from '../../Images';

import "../../styles/slick.scss";

import Slider from "react-slick";

// eslint-disable-next-line
export default () => {
    const sliderRef = useRef();

    const [currentActiveSlide, setCurrentActiveSlide] = useState(0);

    const sliderSettings = {
        infinite: false,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        arrows: false,
        touchThreshold: 10,
        swipeToSlide: true,
        draggable: false,
        // beforeChange: index =>
        //     setCurrentActiveSlide(index),
        afterChange: current => setCurrentActiveSlide(current),
        // responsive: [
        //     {
        //         breakpoint: 767,
        //         settings: {
        //             slidesToShow: 1,
        //             rows: 2,
        //             slidesPerRow: 1,
        //         }
        //     }
        // ],
    };

    const onNextSlide = () => {
        if (sliderRef.current) sliderRef.current.slickNext();


    };

    const onPrevSlide = () => {
        if (sliderRef.current) sliderRef.current.slickPrev();
    };

    const sliderLength = 4;
    return(
        <div className={styles.news_wrap} id="news">
            <div className={global.container}>
                <div className={styles.news}>
                    <div className={styles.news_title}>News & useful tips</div>
                    <div className={styles.news_slider_wrap}>
                        <div className={styles.news_slider}>
                            <Slider {...sliderSettings} ref={sliderRef}>
                                <div className={styles.news_slider_item_wrap}>
                                    <div className={styles.news_slider_item}>
                                        <div className={styles.news_slider_item_date}>24.12.2020</div>
                                        <div className={styles.news_slider_item_title}>Useful Tip #1</div>
                                        <div className={styles.news_slider_item_text}>
                                            Best converting time 10:00 РМ - 05:00 АМ, 02:00 РМ - 10:00 РМ<br/>
                                            Time zone UTC +0
                                        </div>
                                        {/* <div className={styles.news_slider_item_view_more_btn}>
                                            <span>View more</span>
                                            <img src={IMAGES.news_more} alt=""/>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={styles.news_slider_item_wrap}>
                                    <div className={styles.news_slider_item}>
                                        <div className={styles.news_slider_item_date}>16.09.2019</div>
                                        <div className={styles.news_slider_item_title}>Useful Tip #2</div>
                                        <div className={styles.news_slider_item_text}>Best converting age above 50+</div>
                                        {/* <div className={styles.news_slider_item_view_more_btn}>
                                            <span>View more</span>
                                            <img src={IMAGES.news_more} alt=""/>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={styles.news_slider_item_wrap}>
                                    <div className={styles.news_slider_item}>
                                        <div className={styles.news_slider_item_date}>1.06.2019</div>
                                        <div className={styles.news_slider_item_title}>Useful Tip #3</div>
                                        <div className={styles.news_slider_item_text}>Top converting locations - USA, GBR, CAN, AUS</div>
                                        {/* <div className={styles.news_slider_item_view_more_btn}>
                                            <span>View more</span>
                                            <img src={IMAGES.news_more} alt=""/>
                                        </div> */}
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className={styles.news_bottom}>
                        {/* <div className={styles.news_bottom_updates}>New updates this month: <span>10 news & tips</span></div> */}
                        <div className={styles.news_bottom_slider_controls}>
                            <div className={`${styles.news_bottom_slider_control_btn} ${styles.left} ${currentActiveSlide === 0 ? styles.disable : " "}`}
                                 onClick={onPrevSlide}/>
                            <div className={`${styles.news_bottom_slider_control_btn} ${styles.right} ${currentActiveSlide >= sliderLength - 2 ? styles.disable : " "}`}
                                 onClick={onNextSlide}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}