import logoSVG from "../img/logo.svg";

import ab_1SVG from "../img/ab_1.svg";
import ab_2SVG from "../img/ab_2.svg";
import ab_3SVG from "../img/ab_3.svg";
import ab_4SVG from "../img/ab_4.svg";

import news_moreSVG from "../img/news_more.svg";

import ts_quotesSVG from "../img/ts_quotes.svg";

import s_twiSVG from "../img/s_twi.svg";
import s_fbSVG from "../img/s_fb.svg";
import s_ytSVG from "../img/s_yt.svg";
import s_instaSVG from "../img/s_insta.svg";




export const logo = logoSVG;

export const ab_1 = ab_1SVG;
export const ab_2 = ab_2SVG;
export const ab_3 = ab_3SVG;
export const ab_4 = ab_4SVG;

export const news_more = news_moreSVG;

export const ts_quotes = ts_quotesSVG;

export const s_twi = s_twiSVG;
export const s_fb = s_fbSVG;
export const s_yt = s_ytSVG;
export const s_insta = s_instaSVG;