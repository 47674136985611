import React from 'react';
import { Route } from 'react-router-dom';
import MainPage from './page/mainPage';

import TestAffiliat from './components/testAffiliat';

function App() {
    return (
        <div className="App">
            <Route exact path='/' component={MainPage} />
            <Route exact path='/affiliat' component={TestAffiliat} />
        </div>
    );
}

export default App;
