import React, {useRef} from 'react';
import styles from './styles.module.scss';
import * as IMAGES from '../../Images';

import "../../styles/slick.scss";

import Slider from "react-slick";

// eslint-disable-next-line
export default () => {
    const sliderRef = useRef();

    const sliderSettings = {
        infinite: true,
        // centerMode: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        touchThreshold: 10,
        swipeToSlide: true,
        draggable: false,
        variableWidth: true,
        dots: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    // variableWidth: false,
                }
            }
        ],
    };

    const onNextSlide = () => {
        if (sliderRef.current) sliderRef.current.slickNext();


    };

    const onPrevSlide = () => {
        if (sliderRef.current) sliderRef.current.slickPrev();
    };

    return(
        <div className={`${styles.testimonials} testimonials_global`} id="testim">
            <div className={styles.testimonials_title}>Testimonials</div>
            <div className={styles.testimonials_slider_wrap}>
                <Slider {...sliderSettings} ref={sliderRef}>
                    <div className={styles.testimonials_slider_item_wrap}>
                        <div className={styles.testimonials_slider_item}>
                            <div className={styles.testimonials_slider_item_text}>It is awesome to cooperate with Sofia Date and promote their products. <br/>What we love the most is the way they treat their customers.
                                <br/>Always helpful, respectful, and friendly! An excellent partner pleasant to work with.
                            </div>
                            <div className={styles.testimonials_slider_item_bottom}>
                                <div className={styles.testimonials_slider_item_bottom_info}>
                                    <div className={styles.testimonials_slider_item_name}>John</div>
                                </div>
                                <img src={IMAGES.ts_quotes} className={styles.testimonials_slider_item_bottom_quotes} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.testimonials_slider_item_wrap}>
                        <div className={styles.testimonials_slider_item}>
                            <div className={styles.testimonials_slider_item_text}>While looking for an affiliate partner, one should bear in mind the following things: timely payments, fair commissions, free-of-clutter interface, as well as nice customer service. Since Sofia Date corresponds with all these requirements, we cannot but be satisfied with our cooperation. If affiliate marketing is your business, choose The Sofia Date Affiliate Program.</div>
                            <div className={styles.testimonials_slider_item_bottom}>
                                <div className={styles.testimonials_slider_item_bottom_info}>
                                    <div className={styles.testimonials_slider_item_name}>Robert</div>
                                </div>
                                <img src={IMAGES.ts_quotes} className={styles.testimonials_slider_item_bottom_quotes} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.testimonials_slider_item_wrap}>
                        <div className={styles.testimonials_slider_item}>
                            <div className={styles.testimonials_slider_item_text}>We trust Sofia Date because they have really helped us generate much profit. During the first month, we managed to make $6k, and we aren’t going to stop. They provide real help to get great commissions as promised</div>
                            <div className={styles.testimonials_slider_item_bottom}>
                                <div className={styles.testimonials_slider_item_bottom_info}>
                                    <div className={styles.testimonials_slider_item_name}>David</div>
                                </div>
                                <img src={IMAGES.ts_quotes} className={styles.testimonials_slider_item_bottom_quotes} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.testimonials_slider_item_wrap}>
                        <div className={styles.testimonials_slider_item}>
                            <div className={styles.testimonials_slider_item_text}>Stable rates of conversion, honesty, and long-term approach topped off by professional service make Sofia Date a competitive advertiser I recommend everyone engaged in the sphere of affiliate marketing.</div>
                            <div className={styles.testimonials_slider_item_bottom}>
                                <div className={styles.testimonials_slider_item_bottom_info}>
                                    <div className={styles.testimonials_slider_item_name}>Thomas</div>
                                </div>
                                <img src={IMAGES.ts_quotes} className={styles.testimonials_slider_item_bottom_quotes} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.testimonials_slider_item_wrap}>
                        <div className={styles.testimonials_slider_item}>
                            <div className={styles.testimonials_slider_item_text}>New to the dating market and looking for good commissions? Pay attention to Sofia Date. Intuitive platform, transparent commissions, and reliable customer service. What else do you need to become prosperous? Get on board, no regrets guaranteed!</div>
                            <div className={styles.testimonials_slider_item_bottom}>
                                <div className={styles.testimonials_slider_item_bottom_info}>
                                    <div className={styles.testimonials_slider_item_name}>Mark</div>
                                </div>
                                <img src={IMAGES.ts_quotes} className={styles.testimonials_slider_item_bottom_quotes} alt=""/>
                            </div>
                        </div>
                    </div>
                </Slider>
                <div className={`${styles.testimonials_slider_control_btn} ${styles.left} `}
                     onClick={onPrevSlide}/>
                <div className={`${styles.testimonials_slider_control_btn} ${styles.right}`}
                     onClick={onNextSlide}/>
            </div>
        </div>
    )
}