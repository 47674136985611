import React from 'react';
import global from '../../styles/global.module.scss'
import styles from './styles.module.scss';

import * as IMAGES from '../../Images';
import * as DICTIONARY from '../../dictionary';

// eslint-disable-next-line
export default () => {
    return(
        <>
            <div className={styles.footer_wrap}>
                <div className={styles.footer_top_wrap}>
                    <div className={global.container}>
                        <div className={styles.footer_top}>
                            <img src={IMAGES.logo} className={styles.footer_logo} alt="" />
                            <div className={styles.footer_nav}>
                                <a href="#about_us" className={styles.footer_nav_item}>About us</a>
                                <a href="#payouts" className={styles.footer_nav_item}>Payouts</a>
                                <a href="#news" className={styles.footer_nav_item}>News</a>
                                <a href="#testim" className={styles.footer_nav_item}>Testimonials</a>
                            </div>
                            <div className={styles.footer_social}>
                                <div className={styles.footer_social_item}>
                                    <a href={DICTIONARY.LINK_FACEBOOK}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <img src={IMAGES.s_fb} alt="" />
                                    </a>
                                </div>
                                <div className={styles.footer_social_item}>
                                    <a href={DICTIONARY.LINK_TWITTER}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <img src={IMAGES.s_twi} alt="" />
                                    </a>
                                </div>
                                <div className={styles.footer_social_item}>
                                    <a href={DICTIONARY.LINK_YOUTUBE}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <img src={IMAGES.s_yt} alt="" />
                                    </a>
                                </div>
                                <div className={styles.footer_social_item}>
                                    <a href={DICTIONARY.LINK_INSTAGRAM}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <img src={IMAGES.s_insta} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.footer_bottom_wrap}>
                    <div className={global.container}>
                        <div className={styles.footer_bottom}>
                            <div className={styles.footer_copy_text}>© 2020 SofiaDate.com All rights reserved</div>
                            <div className={styles.footer_links}>
                                <div className={styles.footer_links_item}>Terms & Conditions</div>
                                <div className={styles.footer_links_item}>Privacy Policy</div>
                                <div className={styles.footer_links_item}>Cookie Policy</div>
                                <div className={styles.footer_links_item}>Refund Policy</div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}