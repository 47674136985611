import React, { useState } from 'react';
import { checkEmail } from '../../services/methods';
import global from '../../styles/global.module.scss'
import styles from './styles.module.scss';

// eslint-disable-next-line
export default () => {
    const [formData, setFormData] = useState({email: '', password: '',});
    const [passValid, setPassValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);

    const handlePassword = (el) => {
        setFormData({...formData, ...{password: el.currentTarget.value}})
    }

    const handleEmail = (el) => {
        setFormData({...formData, ...{email: el.currentTarget.value}})
    }

    const onSignUp = () => {
        if (formData.email.trim() === '') {
            setEmailValid(false);
            if (formData.password.length < 6) setPassValid(false);

            return false;
        }
        
        if (!checkEmail(formData.email)) {
            setEmailValid(false);
            if (formData.password.length < 6) setPassValid(false);
            
            return false;
        }

        if (formData.password.length < 6) {
            setPassValid(false);
            setEmailValid(true);

            return false;
        }

        window.location.href = 'http://upiple.affise.com/v2/sign/up';
    };

    return(
        <div className={styles.registration_wrap} id="sign_up">
            <div className={global.container}>
                <div className={styles.registration}>
                    <div className={styles.registration_title}>Join us now</div>
                    <div className={styles.registration_desc}>The sooner you join, the more profit you will be able to make!</div>
                    <div className={styles.registration_form}>
                        <div className={styles.registration_col}>
                            <input
                                autocomplete="off"
                                type="name"
                                placeholder=" "
                                className={styles.registration_input_text}
                                id="reg_name"
                                onKeyUp={(e) => handleEmail(e)}
                            />
                            <label htmlFor="reg_name" className={styles.registration_label_text}>Login</label>
                        </div>
                        <div className={styles.registration_col}>
                            <input
                                autocomplete="off"
                                type="password"
                                placeholder=" "
                                className={styles.registration_input_text}
                                id="reg_pass"
                                onKeyUp={(e) => handlePassword(e)}
                            />
                            <label htmlFor="reg_pass" className={styles.registration_label_text}>Password</label>
                        </div>
                        <div className={`${styles.input_label__suggestion} ${(passValid && emailValid) ? '' : styles.error_color}`}>
                           {!emailValid ? 'Invalid email' : 'Minimum 6 characters'}
                        </div>
                        <div className={styles.registration_become_btn} onClick={onSignUp}>Become a member</div>
                    </div>
                </div>
            </div>
        </div>
    )
}