import React from 'react';

import Header from '../../components/header';
import Main from '../../components/main';
import AboutUs from '../../components/about-us';
import Payments from '../../components/payments';
import News from '../../components/news';
import Testimonials from '../../components/testimonials';
import Registration from '../../components/registration';
import Footer from '../../components/footer';

const MainPage = () => {
    return (
        <>
            <Header />
            <Main />
            <AboutUs />
            <Payments />
            <News />
            <Testimonials />
            <Registration />
            <Footer />
        </>
    )
}

export default MainPage;