export const scrollToSignUp = event => {
    event.preventDefault();
    let anchorHref = event.target.attributes.href.value;
    let anchorTarget = document.querySelector(anchorHref);
    anchorTarget.scrollIntoView({ block: 'center', behavior: 'smooth' });
};

export const checkEmail = (email = null) => {
    if (email === null) return false;
    // eslint-disable-next-line
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email);
}